import React, { useEffect, useState } from "react"
import Resume from "../images/Resume.pdf"
import { navigate } from "gatsby"

const IndexPage = () => {
  useEffect(() => {
    navigate(Resume)
  }, [])

  return <></>
}
export default IndexPage
